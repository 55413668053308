import { svg } from 'lit';

const IconSoundOff = () => (
  svg`
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.65772 5.44244L0.575806 2.36389L1.45921 1.47953L16.4994 16.5033L15.616 17.3876L10.1722 11.9498V16.4121C10.1722 17.3657 8.96429 17.7782 8.38104 17.0237L5.00745 12.6596H0.571899V5.44244H3.65772ZM8.97221 10.7511V15.8263L5.59655 11.4596H1.7719V6.64244H4.85903L8.97221 10.7511Z" fill="white"/>
    <path d="M5.81316 4.29124L6.67235 5.14163L8.97221 2.17272V7.41793L10.1722 8.60564V1.58796C10.1722 0.63482 8.96536 0.222057 8.38166 0.975559L5.81316 4.29124Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7102 14.1148C16.7882 12.6988 17.4281 10.9313 17.4281 9.01435C17.4281 6.68706 16.4848 4.58008 14.9597 3.05489L14.1112 3.90342C15.4192 5.21146 16.2281 7.01843 16.2281 9.01435C16.2281 10.5996 15.7178 12.0656 14.8526 13.2571L15.7102 14.1148Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9656 12.3701C14.6014 11.4178 14.972 10.2735 14.972 9.04265C14.972 7.38577 14.3004 5.88575 13.2146 4.79996L12.3661 5.64849C13.2347 6.51712 13.772 7.71714 13.772 9.04265C13.772 9.94088 13.5253 10.7815 13.0959 11.5004L13.9656 12.3701Z" fill="white"/>
  </svg>
  `
);

export default IconSoundOff;
