import { svg } from 'lit';

const ChevronRight = (color: string) => (
  svg`
  <svg
    width="60%"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.24264 0.585729L5.48528 4.82837L1.24264 9.07101"
      stroke=${color}
      stroke-width="1.5"
    />
  </svg>
  `
);

export default ChevronRight;
