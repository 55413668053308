import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import BaseVideo from './base-video';
import stylesVideoPlayer from './styles/video-player';
import stylesVideoSimpleBanner from './styles/video-simple-banner';

const CTA_CLICK_URL_NAME = 'click_url';
const CTA_DESTINATION_URL_NAME = 'destination_url';

@customElement('video-simple-banner')
export default class VideoSimpleBanner extends BaseVideo {
  static styles = [stylesVideoSimpleBanner, stylesVideoPlayer];

  @property({ type: String })
  button_background_color = '';

  @property({ type: String })
  gradient_banner = '';

  render() {
    return html`
      <div class="video-banner-container">
        <div class="banner-row">
          <img class="background-img" src="${this.picture}" alt="${this.video_title}" />
          <span class="gradient-banner" style="background: ${this.gradient_banner}"></span>
          <div class="col">
            <a
              class="banner-link"
              @click="${() => this.handlelink(CTA_CLICK_URL_NAME, CTA_DESTINATION_URL_NAME)}"
              title="${this.video_title}"
            >
              <div class="banner-logo-text">
                <img class="logo" src="${this.primary_logo}" alt="${this.primary_title}" />
                  <div class="text">
                    <span class="primary-title">${this.wrapper_banner_primary_title}</span>
                    <span class="secondary-title">${this.wrapper_banner_secondary_title}</span>
                    <span class="pill" style="background-color: ${this.badge_background_color}">
                    ${this.badge_text}
                  </span>
                  </div>
                <div class="actions">
                  <button type="button" class="action-button" style="background-color: ${this.button_background_color}">
                    <span class="button-content">${this.cta}</span>
                  </button>
                </div>
              </div>
            </a>
          </div>
          <div class="col">
            <div class="video-wrapper-trailer">
              ${this.renderVideoPlayer(true)}
            </div>
          </div>
        </div>
      </div>
    `;
  }
}
