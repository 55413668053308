import { html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ref, createRef, Ref } from 'lit/directives/ref.js';
import BaseBanner from './base-banner';
import IconSoundOff from './icons/icon-sound-off';
import IconSoundOn from './icons/icon-sound-on';
import { translate } from '../script/utils/translate';

const VIDEO_CLICK_URL_NAME = 'video_click_url';
const FINISHED_OVERLAY_CLICK_URL_NAME = 'finished_overlay_click_url';

export default class BaseVideo extends BaseBanner {
  protected videoPlayerId = 'adn-video-player';

  videoStarted = false;

  videoRef:Ref<HTMLVideoElement> = createRef();

  thumbRef:Ref<HTMLElement> = createRef();

  videoCTARef:Ref<HTMLElement> = createRef();

  soundControlRef:Ref<HTMLElement> = createRef();

  @state()
  isMuted = true;

  @state()
  soundControlText = translate('Activar sonido');

  @property({ type: String })
  wrapper_banner_primary_title = '';

  @property({ type: String })
  wrapper_banner_secondary_title = '';

  @property({ type: String })
  finished_overlay_button_text = '';

  @property({ type: String })
  video_title = '';

  @property({ type: String })
  badge_text = '';

  @property({ type: String })
  badge_background_color = '';

  @property({ type: String })
  finished_overlay_destination_url = '';

  @property({ type: String })
  video_destination_url = '';

  @property({ type: String })
  video_dash_url = '';

  @property({ type: String })
  video_hls_url = '';

  @property({ type: String })
  audio_lang = '';

  @property({ type: String })
  thumbnail = '';

  @property({ type: Array })
  finished_overlay_click_url = [];

  @property({ type: Array })
  video_click_url = [];

  protected firstUpdated() {
    super.firstUpdated();
    const video = this.videoRef.value;
    const scriptObserverOptions = {
      root: null,
      rootMargin: '200px',
      threshold: 0.1,
    };

    if (video) {
      const scriptObserver = new IntersectionObserver((entries, observer) => this.injectScript(entries, observer), scriptObserverOptions);
      scriptObserver.observe(video);
    }
  }

  injectScript(entries:IntersectionObserverEntry[], observer:IntersectionObserver) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.shadowRoot?.host.ownerDocument.head.appendChild(this.script());
        this.sendViewTrack();
        observer.disconnect();
      }
    });
  }

  script() {
    const script = document.createElement('script');
    script.src = 'https://http2.mlstatic.com/frontend-assets/adn-frontend-library/adn-video.js';
    script.onload = () => this.initVideoObserver();
    return script;
  }

  initVideoObserver() {
    const video = this.videoRef.value;
    const videoObserverOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    if (video) {
      window?.['adn-video'](this.videoRef.value, {
        sources: [
          { src: this.video_dash_url },
          { src: this.video_hls_url },
        ],
      });
      const videoControlObserver = new IntersectionObserver((entries, observer) => this.videoPlayControl(entries, observer), videoObserverOptions);
      videoControlObserver.observe(video);
    }
  }

  videoPlayControl(entries:IntersectionObserverEntry[], observer:IntersectionObserver) {
    const player = window?.['adn-video'](this.videoRef.value);
    const isPaused = player.paused();

    entries.forEach(async (entry) => {
      if (entry.isIntersecting && !this.videoStarted) {
        await this.startVideo(() => observer.disconnect());
      }

      if (entry.isIntersecting && this.videoStarted && isPaused) {
        await player.play();
      }

      if (!entry.isIntersecting && this.videoStarted && !isPaused) {
        player.pause();
      }
    });
  }

  async startVideo(onFinish:() => void) {
    const player = window['adn-video'](this.videoRef.value);

    await player.play();
    this.videoStarted = true;
    this.thumbRef.value?.classList.add('hide');
    this.soundControlRef.value?.classList.remove('hide');

    player.on('pause', () => {
      if (!player.muted()) {
        this.toggleSound();
      }
    });

    player.on('ended', () => {
      this.finishVideo();
      onFinish();
    });
  }

  finishVideo() {
    this.thumbRef.value?.classList.remove('hide');
    this.soundControlRef.value?.classList.add('hide');
    this.videoCTARef.value?.classList.add('show');
  }

  toggleSound() {
    const player = window['adn-video'](this.videoRef.value);

    this.isMuted = !player.muted();
    player.muted(this.isMuted);
    this.soundControlText = player.muted() ? translate('Activar sonido') : translate('Silenciar');
  }

  renderIconSound() {
    if (this.isMuted) {
      return IconSoundOff();
    }

    return IconSoundOn();
  }

  getAriaLabel() {
    const translatedTrailer: string = translate('Tráiler de');
    const translatedAutoPlay: string = translate('que se reproduce automáticamente');
    return `${translatedTrailer} ${this.video_title} ${translatedAutoPlay}`;
  }

  renderVideoPlayer(videoSimpleBanner = false) {
    return html`
      <div class="video-player-wrapper">
        <a class="video-link" aria-label="${this.getAriaLabel()}" @click="${() => this.handlelink(VIDEO_CLICK_URL_NAME, this.video_destination_url)}">
          <video id="${this.videoPlayerId}" muted class="video-player-skin" ${ref(this.videoRef)}></video>
          <div class="video-hover-effect">
            ${videoSimpleBanner && html`<div class="video-vertical-divider"></div>`}
            <p class="video-title">${this.video_title}</p>
          </div>
          <img class="thumbnail" src="${this.thumbnail}" alt="${this.video_title}" ${ref(this.thumbRef)} />
        </a>
        <button class="video-audio-control hide" role="button" tabindex="0" aria-label="${this.soundControlText}"  @click="${() => this.toggleSound()}" ${ref(this.soundControlRef)}>
          <div class="tooltip">${this.soundControlText}</div>
          ${this.renderIconSound()}
        </button>
        <div class="video-finished-overlay" ${ref(this.videoCTARef)}>
          <a
            class="finished-overlay-link"
            title="${this.video_title}"
            @click="${() => this.handlelink(FINISHED_OVERLAY_CLICK_URL_NAME, this.finished_overlay_destination_url)}"
          >
            ${this.finished_overlay_button_text}
          </a>
        </div>
      </div>
    `;
  }
}
