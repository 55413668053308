import { svg } from 'lit';

const IconSoundOn = () => (
  svg`
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.59655 11.4596L8.97221 15.8263V2.17272L5.50974 6.64244H1.7719V11.4596H5.59655ZM4.92138 5.44244H0.571899V12.6596H5.00745L8.38104 17.0237C8.96429 17.7782 10.1722 17.3657 10.1722 16.4121V1.58796C10.1722 0.63482 8.96536 0.222057 8.38166 0.975559L4.92138 5.44244Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9157 15.0172C16.4667 13.4887 17.4281 11.3637 17.4281 9.01405C17.4281 6.67944 16.4789 4.56653 14.9453 3.04022L14.0951 3.88709C15.4125 5.19637 16.2281 7.00996 16.2281 9.01405C16.2281 11.0314 15.4017 12.8558 14.0689 14.167L14.9157 15.0172Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.211 13.2888C14.2989 12.2027 14.972 10.7012 14.972 9.04245C14.972 7.38232 14.2978 5.87967 13.2082 4.79338L12.3597 5.64191C13.2321 6.51104 13.772 7.71369 13.772 9.04245C13.772 10.3678 13.2349 11.5677 12.3664 12.4363L13.211 13.2888Z" fill="white"/>
    </svg>
  `
);

export default IconSoundOn;
