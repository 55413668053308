export const locales = {
  pt: {
    'Activar sonido': 'Ativar som',
    Silenciar: 'Silenciar',
    'Ver películas, series y más': 'Assistir filmes, séries e muito mais',
    'Tráiler de': 'Trailer de',
    'que se reproduce automáticamente': 'que é reproduzido automaticamente',
  },
};

export const translate = (msg:string) => {
  const { hostname } = window.location;
  const lang = hostname.split('.').includes('br') ? 'pt' : 'es';

  return locales[lang as keyof object]?.[msg as keyof object] || msg;
};
