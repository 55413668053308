export const sponsorAltText: Record<string, string> = {
  '776919-MLA52524088494_112022': 'Limpeza completa para sou casa',
  '629436-MLA52524034938_112022': 'Limpeza completa',
  '721124-MLA52168453248_102022': 'OFERTAS ESPECIAIS 30% OFF FRETE GRÁTIS A PARTIR DE R$199',
  '822903-MLA52168421724_102022': 'OFERTAS ESPECIAIS 30% OFF',
  '743501-MLA52574169479_112022': 'Começou a BlackFriday da FARM 40% OFF',
  '765920-MLA52574141825_112022': 'Começou a BlackFriday da FARM 40% OFF',
  '657860-MLA52220607435_102022': 'OFERTAS PARA A SUA CASA ATE 40% OFF FRETE GRÁTIS A PARTIR DE R$199',
  '986945-MLA52220607447_102022': 'OFERTAS PARA A SUA CASA ATE 40% OFF FRETE GRÁTIS A PARTIR DE R$199',
  '701977-MLA52524195473_112022': 'Os produtosmais desejados 50% OFF MAC',
  '633441-MLA52524162563_112022': 'Os produtosmais desejados 50% OFF MAC',
  '867201-MLA52519881877_112022': 'O melhor de Nike 50% OFF',
  '884684-MLA52519881883_112022': 'O melhor de Nike 50% OFF',
  '602142-MLA52267621709_112022': 'Insider Roupa íntima ate 20%OFF',
  '657621-MLA52267594966_112022': 'Insider Roupa íntima ate 20%OFF',
  '605227-MLA52519281259_112022': 'Se prepare para os jogos com ofertas exclusivas 30% OFF, FRETE GRÁTIS A PARTIR DE R$109.90 THE BAR.Produto proibido para menores de 18 anos. Beba com moderação,',
  '960769-MLA52519281274_112022': 'Ofertas exclusivasaté 30% OFF THE BAR.Produto proibido para menores de 18 anos. Beba com moderação,',
  '667573-MLA52519182759_112022': 'Drinks para oesquenta da torcida 30% off. JOHNNIE WALKER. produto proibido para menores de 18 anos. Beba com moderação.',
  '971987-MLA52519281233_112022': 'Drinks para oesquenta da torcida 30% off. JOHNNIE WALKER. produto proibido para menores de 18 anos. Beba com moderação.',
  '737917-MLA52281246694_112022': 'Päo de Acúcar. Fraldas Pamperse Perfumaria FRETE GRÁTIS A PARTIR DE R$199, ATE 40% OFF',
  '990516-MLA52281294243_112022': 'Päo de Acúcar. Fraldas Pamperse Perfumaria FRETE GRÁTIS A PARTIR DE R$199, ATE 40% OFF',
  '758802-MLA52281246784_112022': 'Päo de Acúcar. Produtos de limpeza FRETE GRÁTIS A PARTIR DE R$199, ATE 40% OFF',
  '755724-MLA52281294340_112022': 'Päo de Acúcar. Produtos de limpeza FRETE GRÁTIS A PARTIR DE R$199, ATE 40% OFF',
  '971745-MLA52281312241_112022': 'Päo de Acúcar.Cerveja e Destilados FRETE GRÁTIS A PARTIR DE R$199, ATE 40% OFF',
  '837828-MLA52281294422_112022': 'Päo de Acúcar.Cerveja e Destilados FRETE GRÁTIS A PARTIR DE R$199, ATE 40% OFF',
  '971183-MLA52287632971_112022': 'Camisetas funcionais Insider ATE 25% OFF, FRETE GRÁTIS',
  '842790-MLA52287735666_112022': 'Camisetas funcionais Insider ATE 25% OFF, FRETE GRÁTIS',
  '766698-MLA52309206290_112022': 'Uma seleção de peças incríveis ate 50%OFF AMARO',
  '978900-MLA52309267210_112022': 'Uma seleção de peças incríveis ate 50%OFF AMARO',
  '658085-MLA52519864303_112022': 'Black Friday Pernod Ricard ate 30% OFF',
  '675004-MLA52519776782_112022': 'Black Friday Pernod Ricard ate 30% OFF',
  '636833-MLA52384436089_112022': 'AS MELHORES OFERTASDE CERVEJAS, FRETE GRÁTIS A PARTIR R$199. Päo de Acúcar.',
  '980479-MLA52384412197_112022': 'AS MELHORES OFERTASDE CERVEJAS, FRETE GRÁTIS A PARTIR R$199. Päo de Acúcar.',
  '685623-MLA52519225784_112022': 'Looks incríveis AMARO ate 60% OFF',
  '821329-MLA52519182943_112022': 'Looks incríveis AMARO ate 60% OFF',
  '969587-MLA52419495440_112022': 'Descontão nas especiais 50% 0FF ambev',
  '734240-MLA52419495445_112022': 'Descontão nas especiais 50% 0FF ambev',
  '680474-MLA52520516801_112022': 'BACARDí Gin Bombay Sapphire,Bramble e muito mais. Ate 40% OFF. FRETE GRÁTIS A PARTIR DE R$89,90',
  '660252-MLA52520547608_112022': 'BACARDí Gin Bombay Sapphire,Bramble e muito mais. Ate 40% OFF. FRETE GRÁTIS A PARTIR DE R$89,90',
  '817731-MLA52464252636_112022': 'Os melhores eletrônicos. Ate 60% OFF. FRETE GRÁTIS. WebFones',
  '969319-MLA52464252647_112022': 'Os melhores eletrônicos. Ate 60% OFF. FRETE GRÁTIS. WebFones',
  '810002-MLA52523748244_112022': 'As melhores cervejas da Pré Black, ate 15% off ambev ',
  '801277-MLA52523748257_112022': 'As melhores cervejas da Pré Black, ate 15% off ambev ',
  '964247-MLA52518430000_112022': 'Ofertas Grupo Heineken ate 35% OFF, FRETE GRÁTIS. Heineken',
  '994006-MLA52518536008_112022': 'Ofertas Grupo Heineken ate 35% OFF, FRETE GRÁTIS. Heineken',
  '847253-MLA52518684556_112022': 'Grandes ofertas para fazer seu drink em casa. Ate 40% OFF. FRETE GRÁTIS.',
  '866656-MLA52518684565_112022': 'Grandes ofertas para fazer seu drink em casa. Ate 40% OFF. FRETE GRÁTIS.',
  '850592-MLA52523405254_112022': 'Black Friday Hering. ATE 75% OFF',
  '916652-MLA52523405262_112022': 'Black Friday Hering. ATE 75% OFF',
  '725011-MLA52518944331_112022': 'Care Friday Johnson&Johnson. Ate 40% OFF. FRETE GRÁTIS ',
  '890634-MLA52518944338_112022': 'Care Friday Johnson&Johnson. Ate 40% OFF. FRETE GRÁTIS ',
  '911015-MLA52518944373_112022': 'Philco Friday. Ate 40% OFF. FRETE GRÁTIS ',
  '756753-MLA52518930513_112022': 'Philco Friday. Ate 40% OFF. FRETE GRÁTIS ',
  '626202-MLA52519076224_112022': 'Ofertas pra você marcar um golaço. Ate 60% OFF. FRETE GRÁTIS ',
  '727213-MLA52519039396_112022': 'Ofertas pra você marcar um golaço. Ate 60% OFF. FRETE GRÁTIS ',
  '681942-MLA52519076793_112022': 'Roupas essenciascom tecnologia. Ate 25% OFF. FRETE GRÁTIS. INSIDER',
  '714189-MLA52519126454_112022': 'Roupas essenciascom tecnologia. Ate 25% OFF. FRETE GRÁTIS. INSIDER',
  '670535-MLA52519281338_112022': 'Celulares e Informática 18X No cartão de crédito. Frete gratis',
  '735787-MLA52519182899_112022': 'Celulares e Informática 18X No cartão de crédito. Frete gratis',
  '865043-MLA52519343395_112022': 'Eletrodomésticos 18X No cartão de crédito. Frete gratis',
  '651148-MLA52519343402_112022': 'Eletrodomésticos 18X No cartão de crédito. Frete gratis',
  '828790-MLA52519976090_112022': 'Ofertas para seucuidado pessoal. Ate 40% OFF. Colgate Palmolive',
  '872685-MLA52519976099_112022': 'Ofertas para seucuidado pessoal. Ate 40% OFF. Colgate Palmolive',
  '783082-MLA52519916721_112022': 'As melhores ofertas intel. Ate 40% OFF 18X No cartão de crédito',
  '641428-MLA52519916734_112022': 'Pronto para ação intel ATÉ 40% OFF',
  '901400-MLA52520007399_112022': 'Ofertas para um sorriso saudável ATE 40% OFF Colgate',
  '630119-MLA52520085102_112022': 'Ofertas para um sorriso saudável ATE 40% OFF Colgate',
  '949063-MLA52520238276_112022': 'Ofertas imperdíveis Consul 30% OFF, Frete gratis',
  '731566-MLA52520238283_112022': 'Ofertas imperdíveis Consul 30% OFF, Frete gratis',
  '665794-MLA52520238968_112022': 'Black Friday Philips Colgate 30% OFF, Frete gratis',
  '653103-MLA52520368179_112022': 'Black Friday Philips Colgate 30% OFF, Frete gratis',
  '977830-MLA52520314200_112022': 'SAMSUNG Notebooks e Tablets Samsung 30% OFF, Frete gratis',
  '875956-MLA52520173749_112022': 'Linhas de Notebooks e Tablets Samsung 30% OFF',
  '948267-MLA52520368985_112022': 'Melhores ofertas Mobcom. Ate 20% OFF. 18X No cartão de crédito',
  '627382-MLA52520368998_112022': 'Melhores ofertas Mobcom. 18X No cartão de crédito',
  '734293-MLA52520516079_112022': 'Melhores ofertas Mobcom. Ate 20% OFF. 18X No cartão de crédito',
  '933531-MLA52520384880_112022': 'Melhores ofertas Mobcom. 18X No cartão de crédito',
  '982296-MLA52520615110_112022': 'Päo de Acúcar.Especiais do Pão. FRETE GRÁTIS A PARTIR DE R$199, ATE 40% OFF',
  '881063-MLA52520547532_112022': 'Päo de Acúcar.Especiais do Pão. FRETE GRÁTIS A PARTIR DE R$199, ATE 40% OFF',
  '620742-MLA52520547756_112022': 'As melhores marcasate. Ate 10% OFF, Frete gratis. Black&Decker, STANLEY, Irwin, DeWalt.',
  '940733-MLA52520615317_112022': 'As melhores marcasate. Ate 10% OFF, Frete gratis. Black&Decker, STANLEY, Irwin, DeWalt.',
  '671488-MLA52520615386_112022': 'Para a casa e família sc Johnson. Ate 40% OFF. FRETE GRÁTIS ',
  '772833-MLA52520547818_112022': 'Para a casa e família sc Johnson. Ate 40% OFF.',
  '747189-MLA52520745001_112022': 'DELLTechnologies. A hora de compraré agora. FRETE GRÁTIS ',
  '880037-MLA52520745008_112022': 'DELLTechnologies. A hora de compraré agora. FRETE GRÁTIS ',
  '914050-MLA52520745166_112022': 'Ofertas imperdíveis WAP. Ate 30% OFF. FRETE GRÁTIS ',
  '971199-MLA52520745171_112022': 'Ofertas imperdíveis WAP. Ate 30% OFF. FRETE GRÁTIS ',
  '704861-MLA52520745474_112022': 'Confira nossas ofertas Logitech G',
  '917163-MLA52520806016_112022': 'Confira nossas ofertas Logitech G',
  '683281-MLA52520706853_112022': 'Confira nossas ofertas Logitech. Ate 40% OFF.',
  '672805-MLA52520706854_112022': 'Confira nossas ofertas Logitech. Ate 40% OFF.',
  '937048-MLA52520706986_112022': 'Descontos imperdíveis ACER',
  '677060-MLA52520745802_112022': 'Descontos imperdíveis ACER',
  '854796-MLA52537034474_112022': 'CHEVROLET Qualidade e preço baixo. Ate 40% OFF. FRETE GRÁTIS ',
  '683032-MLA52537101197_112022': 'CHEVROLET Qualidade e preço baixo. Ate 40% OFF. FRETE GRÁTIS ',
  '664905-MLA52544379684_112022': 'Coffee Friday L\'or.Ate 45% OFF. FRETE GRÁTIS ',
  '732151-MLA52544379694_112022': 'Coffee Friday L\'or.Ate 45% OFF. FRETE GRÁTIS ',
  '649519-MLA52544510132_112022': 'Super Friday Café Pilão. Ate 45% OFF. FRETE GRÁTIS ',
  '990385-MLA52544510148_112022': 'Super Friday Café Pilão. Ate 45% OFF. FRETE GRÁTIS ',
  '860002-MLA52554176330_112022': 'Black Friday Amaro. Ate 60% OFF. FRETE GRÁTIS ',
  '616790-MLA52554176353_112022': 'Black Friday Amaro. Ate 60% OFF. FRETE GRÁTIS ',
  '911814-MLA52595093894_112022': 'Smartphones black da web DESCONTOS DE ATE R$ 500. FRETE GRÁTIS. WEBFONES',
  '720275-MLA52595156082_112022': 'Smartphones black da web DESCONTOS DE ATE R$ 500. FRETE GRÁTIS. WEBFONES',
  '690812-MLA52595144277_112022': 'Smartphones black da web DESCONTOS DE ATE R$ 500. FRETE GRÁTIS. WEBFONES',
  '669824-MLA52595112748_112022': 'Smartphones black da web DESCONTOS DE ATE R$ 500. FRETE GRÁTIS. WEBFONES',
  '636758-MLA52667742668_112022': 'ANTECIPAMOSO SEU NATAL. Ate 40% OFF. FRETE GRÁTIS A PARTIR DE R$199. Päo de Acúcar',
  '977037-MLA52667742680_112022': 'ANTECIPAMOSO SEU NATAL. Ate 40% OFF. FRETE GRÁTIS A PARTIR DE R$199. Päo de Acúcar',
  '783654-MLA52733373774_122022': 'SC Johnson. PROTEÇÃO DA SUA CASA. ATÉ 40% OFF',
  '987950-MLA52733373779_122022': 'SC Johnson. PROTEÇÃO DA SUA CASA. ATÉ 40% OFF',
  '900567-MLA52822724117_122022': 'TUDO A TEMPO DO NATAL. Compre um Galaxy M53 5G. 18X No cartão de crédito. FRETE GRÁTIS. SAMSUNG',
  '972688-MLA52822573627_122022': 'TUDO A TEMPO DO NATAL. Compre um Galaxy M53 5G. 18X No cartão de crédito. FRETE GRÁTIS. SAMSUNG',
  '916152-MLA52822794302_122022': 'SAMSUNG. Compre um Galaxy S20 FE 5G. ATÉ 40% OFF',
  '855472-MLA52822794397_122022': 'SAMSUNG. Compre um Galaxy S20 FE 5G. ATÉ 40% OFF',
  '641637-MLA52853253722_122022': 'LOJA OFICIAL AMARO NO MERCADO LIVRE.  Ate 60% OFF. FRETE GRÁTIS.',
  '927266-MLA52853253728_122022': 'LOJA OFICIAL AMARO NO MERCADO LIVRE.  Ate 60% OFF. FRETE GRÁTIS.',
  '910056-MLA52853672450_122022': 'A magia do Natal está na Cacau Show. 15% OFF',
  '864976-MLA52853672455_122022': 'A magia do Natal está na Cacau Show. 15% OFF',
  '977865-MLA52872213240_122022': 'Seu presente mais esperado chegou intel 35% OFF.18X No cartão de crédito.',
  '747119-MLA52872158868_122022': 'Seu presente mais esperado chegou intel 35% OFF.18X No cartão de crédito.',
  '637951-MLA52890714857_122022': 'TUDO A TEMPO DO NATAL. Presente de Natal é na FARM',
  '797913-MLA52890755513_122022': 'TUDO A TEMPO DO NATAL. Presente de Natal é na FARM',
  '768691-MLA52891371580_122022': 'Produtos Omron para sua saúd ATÉ 30% OFF',
  '899549-MLA52891389404_122022': 'Produtos Omron para sua saúd ATÉ 30% OFF',
  '782038-MLA52892675674_122022': 'PAO ae Acúcar SUAS CERVEJAS FAVORITAS ATÉ 25% OFF',
  '898393-MLA52892693550_122022': 'PAO ae Acúcar SUAS CERVEJAS FAVORITAS ATÉ 25% OFF',
  '952344-MLA52907395925_122022': 'Presenteie com Hering AQUI TEM FRETE GRÁTIS',
  '863731-MLA52907479490_122022': 'Presenteie com Hering AQUI TEM FRETE GRÁTIS',
  '679331-MLA53002343328_122022': 'PAO ae Acúcar Abasteça sua casa TEM FRETE GRÁTIS',
  '786235-MLA53002343452_122022': 'PAO ae Acúcar Abasteça sua casa TEM FRETE GRÁTIS',
  '905315-MLA53003554708_122022': 'SAMSUNG COMPRE UM GALAXY M53 5G',
  '837685-MLA53003584210_122022': 'SAMSUNG COMPRE UM GALAXY M53 5G',
  '779493-MLA53016914741_122022': 'PAO ae Acúcar AS MELHORES OFERTAS DE CERVEJAS FRETE GRÁTIS',
  '996067-MLA53016989322_122022': 'PAO ae Acúcar OFERTAS DE CERVEJAS SUPERMERCADO FRETE GRÁTIS A PARTIR DE R$199',
  '739630-MLA53206909550_012023': 'HERING 70% OFF',
  '795094-MLA53206886741_012023': 'HERING',
  '953534-MLA53230108245_012023': 'SAMSUNG COMPRE GALAXY A03 CORE',
  '778110-MLA53230016800_012023': 'SAMSUNG COMPRE GALAXY A03 CORE',
  '772081-MLA53245705248_012023': 'PAO ae Acúcar SUA CASA ABASTECIDA ATÉ 30% OFF',
  '674383-MLA53245661521_012023': 'PAO ae Acúcar SUA CASA ABASTECIDA ATÉ 30% OFF',
  '901067-MLA53249535673_012023': 'OFERTAS DE TECNOLOGIA ATE 30% OFF',
  '907139-MLA53249494954_012023': 'OFERTAS DE TECNOLOGIA ATE 30% OFF',
  '766639-MLA53267094923_012023': 'DESCONTO NAS MELHORES FERRAMENTAS BLACK DECKER, STANLEY, IRWIN, DEWALT',
  '691589-MLA53267094941_012023': 'DESCONTO NAS MELHORES FERRAMENTAS BLACK DECKER, STANLEY, IRWIN, DEWALT',
  '801668-MLA53271910040_012023': 'SC Johnson FÉRIAS DE VERÃO DESCONTOS PARA VOCÊ PREPARAR A SUA CASA 20% 0FF',
  '627114-MLA53271909124_012023': 'SC Johnson DESCONTOS PARA SUA CASA ATÉ 20% OFF',
  '995796-MLA53327281342_012023': 'PAO ae Acúcar CERVEJAS E DESTILADOS ATÉ 25% OFF',
  '774732-MLA53327183804_012023': 'PAO ae Acúcar CERVEJAS E DESTILADOS ATÉ 25% OFF',
  '666834-MLA53332473604_012023': 'PAO ae Acúcar SUAS BEBIDAS FAVORITAS ATÉ 25% OFF',
  '822385-MLA53332599105_012023': 'PAO ae Acúcar SUAS BEBIDAS FAVORITAS ATÉ 25% OFF',
  '707878-MLA53349431714_012023': 'SUPER LANÇAMENTO SAMSUNG GALAXY A04E',
  '612947-MLA53349431726_012023': 'SAMSUNG GALAXY A04E',
  '656253-MLA53377958989_012023': 'SAMSUNG GALAXY UNPACKED LANÇAMENTO ESPECIAL COM OFERTA EXCLUSIVA',
  '943337-MLA53377998654_012023': 'SAMSUNG LANÇAMENTO ESPECIAL COM OFERTA EXCLUSIVA',
  '967238-MLA53562607041_022023': 'SAMSUNG PROMOÇÃO DE LANÇAMENTO GALAXY S23 5G ',
  '684131-MLA53562591269_022023': 'SAMSUNG PROMOÇÃO DE LANÇAMENTO GALAXY S23 5G',
  '672449-MLA53568273234_022023': 'Elma Chips SUA ATITUDE DE CARNAVAL ATÉ 30% OFF',
  '814189-MLA53568273262_022023': 'Elma Chips SUA ATITUDE DE CARNAVAL ATÉ 30% OFF',
  '949366-MLA53568979080_022023': 'SAMSUNG OFERTAS IMPERDÍVEIS NA GALAXY WEEK',
  '704541-MLA53568959498_022023': 'SAMSUNG OFERTAS IMPERDÍVEIS GALAXY WEEK',
  '970789-MLA53585718381_022023': 'pao ae Acúcar  SUAS CERVEJAS FAVORITAS ATE 25% OFF',
  '841905-MLA53585702534_022023': 'pao ae Acúcar SUAS CERVEJAS FAVORITAS ATÉ 25% OFF',
  '893622-MLA53640581624_022023': 'SAMSUNG DOBRO DE MEMÓRIA PELO MESMO PREÇO',
  '934120-MLA53640581634_022023': 'SAMSUNG DOBRO DE MEMÓRIA PELO MESMO PREÇO',
  '631979-MLA53667318682_022023': 'loGitech COMPRE QUALQUER PRODUTO, GANHE UM CURSO E CONCORRA UMA VIAGEM',
  '607870-MLA53667331519_022023': 'LoGitech COMPRE E CONCORRA',
  '666629-MLA53668844235_022023': 'SAMSUNG GALAXY WEEK COMPRE O GALAXY M53 5G + A03 CORE',
  '896986-MLA53668859087_022023': 'SAMSUNG  COMPRE O M53 + A03 CORE +10% OFF USE O CUPOM  COMBOSSG',
  '919445-MLA53687236515_022023': 'LOOKS DE VERÃO LIQUIDA HERING  ATE 70% OFF GENHE 10% OFF PAGANDO COM PIX',
  '953836-MLA53687281021_022023': 'HERING LIQUIDA HERING ate 70% off',
  '819360-MLA53736090007_022023': 'SAMSUNG GAMING HUB SEU VIDEOGAME DENTRO DA SUA TV AQUI TEM FRETE GRATIS',
  '987573-MLA53736051449_022023': 'SAMSUNG SEU VIDEOGAME DENTRO DA TV AQUI TEM FRETE GRÁTIS',
  '914296-MLA53807886127_022023': 'CARRARO MÓVEIS PARA SUA CASA 35% OFF',
  '979351-MLA53807289785_022023': 'CARRARO MÓVEIS PARA SUA CASA ATÉ 35% OFF',
  '828516-MLA53831966005_022023': 'pao ae Acúcar AS MELHORES CERVEJAS supermercado FRETE GRÁTIS 30% OFF Promoção sujeita a disponibilidade de estoque ',
  '691871-MLA53831966009_022023': 'pao ae Acúcar AS MELHORES CERVEJAS 30% OFF Promoção sujeita a disponibilidade de estoque .Aprecie com moderação. Náo compartilhe com menores de 18 anos.',
  '691396-MLA53831953185_022023': 'AMSTEL PURO MALTE FEITA COM LEVEDURA HOLANDESA ACOMPANHE COM AMSTEL A CASA MAIS VIGIADA DO BRASIL',
  '858736-MLA53831764850_022023': 'ACOMPANHE COM AMSTEL Beba com moderação. Proibido para menores de 18 anos.',
  '723912-MLA53857530592_022023': 'intel PRA QUEM GOSTA DE JOGOS EM TEMPO REAL SAIBA MAIS',
  '923770-MLA53857620336_022023': 'intel JOGOS EM TEMPO REAL',
  '801492-MLA53886699521_022023': 'ISMAFER ISMAFER FERRAMENTAS É AQUI 50% OFF Confira',
  '865208-MLA53886636828_022023': 'ISMAFER FERRAMENTAS É AQUI ATÉ 50% OFF',
  '681899-MLA54011317623_022023': 'samsung ressaca de carnaval compre o galaxy s22 5g + A04E',
  '890074-MLA54011363614_022023': 'pao ae Acúcar SUAS CERVEJAS FAVORITAS ATE 30% OFF',
  '751620-MLA54077569062_022023': 'pao ae Acúcar SUAS CERVEJAS FAVORITAS  ATÉ 30% OFF',
  '841055-MLA54077423750_022023': 'AMORTECEDOR É COFAP ',
  '972627-MLA54202625308_032023': 'AMORTECEDOR É COFAP ',
  '828389-MLA54202678095_032023': 'samsung galaxy a04s DIA DO CONSUMIDOR APROVEITE OS DESCONTOS ESPECIAIS',
  '724606-MLA54227830422_032023': 'samsung descontos especiais',
  '624413-MLA54227556913_032023': 'COMPRE EM UM CLIQUE  DO BBB PARA SUA CASA Wap',
  '675640-MLA54248045661_032023': 'COMPRE EM UM CLIQUE  DO BBB PARA SUA CASA Wap',
  '719411-MLA54248663274_032023': 'ARNO MES DO CONSUMIDOR Ê COM ARNO',
  '965571-MLA54249471345_032023': 'ARNO MES DO CONSUMIDOR Ê COM ARNO',
  '959081-MLA54249590679_032023': 'DIAGEO DIA DO CONSUMIDOR  MELHORES BEBIDAS ',
  '670874-MLA54263286683_032023': 'DIAGEO DIA DO CONSUMIDOR  MELHORES BEBIDAS ',
  '863099-MLA54263075207_032023': 'SAMSUNG A14 5G Galaxy DIA DO CONSUMIDOR APROVEITE OS DESCONTOS ESPECIAIS SAMSUNG',
  '970362-MLA54273591741_032023': 'DESCONTOS ESPECIAIS  SAMSUNG',
  '678876-MLA54274310550_032023': 'LAVADORAS BRASTEMP ESPIE AS MELHORES OFERTAS  ',
  '955826-MLA54286208421_032023': 'LAVADORAS BRASTEMP ESPIE AS MELHORES OFERTAS  ',
  '937321-MLA54286208431_032023': 'pao ae Acúcar AS MELHORES CERVEJAS',
  '630833-MLA54293987012_032023': 'pao ae Acúcar AS MELHORES CERVEJAS',
  '866716-MLA54293984974_032023': 'heineken dia do consumidor grupo heineken ate 30% off',
  '658819-MLA54362167375_032023': 'heineken dia do consumidor grupo heineken ate 30% off',
  '735659-MLA54362357049_032023': 'monDial À ESCOLHA INTELIGENTE PARA CUIDAR DE VOCÊ',
  '907957-MLA54385361483_032023': 'monDial À ESCOLHA INTELIGENTE PARA CUIDAR DE VOCÊ',
  '754787-MLA54385498999_032023': 'AIRFRYER É MONDIAL',
  '676890-MLA54385489053_032023': 'AIRFRYER É MONDIAL',
  '634779-MLA54387178500_032023': 'LOUCOS POR CHOCOLATE  PÁSCOA LACTA É MAIS GOSTOSA',
  '619534-MLA54517964584_032023': 'LOUCOS POR CHOCOLATE  PÁSCOA LACTA É MAIS GOSTOSA',
  '600783-MLA54518010018_032023': 'NIKE AIR MAX UM MÊS ESPECIAL PARA ESTE ÍCONE ',
  '912590-MLA54616972067_032023': 'NIKE AIR MAX UM MÊS ESPECIAL PARA ESTE ÍCONE ',
  '889513-MLA54619547760_032023': 'GALAXY S21 FE SG  OFERTAS IMPERDÍVEIS',
  '788295-MLA54696228380_032023': 'GALAXY S21 FE SG  OFERTAS IMPERDÍVEIS',
  '939507-MLA54692836271_032023': 'pao ae Acúcar AS MELHORES CERVEJAS',
  '972842-MLA54785625374_032023': 'pao ae Acúcar AS MELHORES CERVEJAS',
  '940142-MLA54781682507_032023': 'as melhores ofertas de notebook ate 20% off',
  '641216-MLA54837047408_042023': 'as melhores ofertas de notebook ate 20% off',
  '963528-MLA54837180358_042023': 'SAMSUNG BOOK INTELº CORE” I5 ATE 30% off',
  '983254-MLA54846266379_042023': 'SAMSUNG BOOK INTELº CORE” I5 30% off',
  '608272-MLA54850508618_042023': 'SAMSUNG WEEK GAMING EDITION',
  '644760-MLA54916821555_042023': 'SAMSUNG WEEK GAMING EDITION',
  '744105-MLA54916787331_042023': 'SAMSUNG WEEK GAMING EDITION',
  '915690-MLA54926177035_042023': 'SAMSUNG WEEK GAMING EDITION',
  '674735-MLA54926177045_042023': 'pao ae Acúcar GELADEIRA ABASTECIDA COM AS MELHORES CERVEJAS',
  '826056-MLA54953269426_042023': 'pao ae Acúcar GELADEIRA ABASTECIDA COM AS MELHORES CERVEJAS',
  '809216-MLA54953269430_042023': 'COMPRE R$ 25 EM PRODUTOS DANONE CONCORRA A MAIS DE 1 MILHÃO EM PRÊMIOS',
  '840159-MLA54949828455_042023': 'COMPRE R$ 25 EM PRODUTOS DANONE CONCORRA A MAIS DE 1 MILHÃO EM PRÊMIOS',
  '613777-MLA54949763409_042023': 'LANÇAMENTO LOJA OFICIAL ELECTROLUX DESCONTOS EXCLUSIVOS PARA SUA CASA BEM VIVIDA',
  '844735-MLA54956232686_042023': 'LANÇAMENTO LOJA OFICIAL ELECTROLUX DESCONTOS EXCLUSIVOS PARA SUA CASA BEM VIVIDA',
  '985287-MLA54956248542_042023': 'SAMSUNG COMPRE GALAXY S23 5G  E BUDS2 COM DESCONTO',
  '742321-MLA69097403247_042023': 'SAMSUNG COMPRE GALAXY S23 5G  E BUDS2 COM DESCONTO',
  '634114-MLA69098629325_042023': 'PRESENTEIE QUEM VOCÊ AMA NIKE DAY',
  '843999-MLA69096689180_042023': 'PRESENTEIE QUEM VOCÊ AMA NIKE DAY',
  '664379-MLA54978467566_042023': 'SAMSUNG COMPRE GALAXY S23 5G  E BUDS2 COM DESCONTO',
  '753384-MLA69116972321_042023': 'SAMSUNG COMPRE GALAXY S23 5G  E BUDS2 COM DESCONTO',
  '745854-MLA54974286209_042023': 'PRESENTEIE SUA MÃE COM TABLETS SAMSUNG  SAMSUNG ATE 20% OFF',
  '852235-MLA54982215914_042023': 'PRESENTEIE SUA MÃE COM TABLETS SAMSUNG  SAMSUNG ATE  20% OFF',
  '935517-MLA54976454697_042023': 'PRESENTEIE SUA MÃE COM NOTEBOOKS SAMSUNG  SAMSUNG ATE 20% OFF',
  '960441-MLA54976450679_042023': 'PRESENTEIE SUA MÃE COM NOTEBOOKS SAMSUNG  SAMSUNG ATE 20% OFF',
  '862210-MLA54976450681_042023': 'pao ae Acúcar AS MELHORES CERVEJAS ATE 30% OFF',
  '911423-MLA54976545695_042023': 'pao ae Acúcar AS MELHORES CERVEJAS ATE 30% OFF',
  '970827-MLA69144497373_042023': 'SUA MÃE MERECE VELOCIDADE 5G. 18X No cartão de crédito. Samsung.',
  '669473-MLA69140401910_042023': 'SUA MÃE MERECE VELOCIDADE 5G. 18X No cartão de crédito. Samsung.',
  '723804-MLA54976701263_042023': 'SUA MÃE MERECE VELOCIDADE 5G. 18X No cartão de crédito, Frete gratis. Samsung',
  '673974-MLA69317963605_052023': 'SUA MÃE MERECE VELOCIDADE 5G. 18X No cartão de crédito. Samsung.',
  '973387-MLA69317991953_052023': 'GELADEIRA ABASTECIDA COM AS MELHORES CERVEJAS. Ate 30% OFF, FRETE GRÁTIS A PARTIR DE R$199',
  '952109-MLA69398255304_052023': 'AS MELHORES CERVEJAS. Ate 30% OFF, FRETE GRÁTIS A PARTIR DE R$199. Päo de Acúcar.',
  '641766-MLA69408121349_052023': 'TODO DIA É DIA DE PRESENTEAR. Ate 50% OFF. Nike',
  '803791-MLA69452915546_052023': 'TODO DIA É DIA DE PRESENTEAR NIKE  ATIVE OS CUPONS DE ATE +15% OFF',
  '868073-MLA69452915562_052023': 'AS MELHORES OFERTAS DE CERVEJA . Ate 30% OFF, FRETE GRÁTIS A PARTIR DE R$199',
  '625918-MLA69741216828_062023': 'AS MELHORES OFERTAS DE CERVEJA . Ate 30% OFF, FRETE GRÁTIS A PARTIR DE R$199',
  '637242-MLA69741178802_062023': 'COMPRE E GANHE GALAXY BUDS LIVE',
  '611487-MLA69802002426_062023': 'COMPRE E GANHE GALAXY BUDS LIVE',
  '850303-MLA69818539491_062023': 'NOTEBOOKS SAMSUNG Ate 20% OFF',
  '911299-MLA69854818223_062023': 'DIA DOS NAMORADOS NOTEBOOKS SAMSUNG  Ate 20% OFF',
  '890487-MLA69837401970_062023': 'AS MELHORES CERVEJAS. Ate 30% OFF, FRETE GRÁTIS A PARTIR DE R$199. Päo de Acúcar.',
  '614125-MLA69965278708_062023': 'AS MELHORES CERVEJAS. Ate 30% OFF, FRETE GRÁTIS A PARTIR DE R$199. Päo de Acúcar.',
  '891840-MLA69984088335_062023': 'AS SUAS CERVEJAS FAVORITAS. Ate 30% OFF, FRETE GRÁTIS A PARTIR DE R$199. Päo de Acúcar.',
  '938060-MLA70268475353_072023': 'CERVEJAS FAVORITAS. Ate 30% OFF, FRETE GRÁTIS A PARTIR DE R$199. Päo de Acúcar.',
  '923073-MLA70268475363_072023': 'ECOSSISTEMA É SAMSUNG. Ate 40% OFF, Frete gratis.',
  '637570-MLA70281011216_072023': 'ECOSSISTEMA É SAMSUNG. Ate 40% OFF, Frete gratis.',
  '866839-MLA70281011236_072023': 'OFERTAS  DE CERVEJAS, Ate 35% OFF. Päo de Acúcar.',
  '812763-MLA70535936451_072023': 'AS MELHORES OFERTAS  DE CERVEJAS, Ate 35% OFF. FRETE GRÁTIS A PARTIR DE R$199. Päo de Acúcar.',
  '805222-MLA70535936453_072023': 'SUAS CERVEJAS FAVORITAS. Ate 30% OFF, FRETE GRÁTIS A PARTIR DE R$199. Päo de Acúcar.',
  '601694-MLA70797687897_072023': 'CERVEJAS FAVORITAS ATÉ 30%OFF. Päo de Acúcar.',
  '929520-MLA70796874533_072023': 'AS SUAS CERVEJAS FAVORITAS. ATÉ 30%OFF. Päo de Acúcar.',
  '791521-MLA71059155053_082023': 'CERVEJAS Ei sa FAVORITAS. ATÉ 30%OFF. Päo de Acúcar.',
  '659514-MLA71058697545_082023': 'SAMSUNG COMPRE TAB S9 E GANHE DESCONTO NO WATCH6',
  '963302-MLA71185552870_082023': 'LANÇAMENTO samsung (50%off  CUPOM: LANCAMENTOS9)',
  '923741-MLA71186087362_082023': 'OFERTAS ESPECIAIS. FRETE GRÁTIS. LG',
  '876149-MLA71212896258_082023': 'OFERTAS ESPECIAIS. FRETE GRÁTIS. LG',
  '714203-MLA71212986432_082023': 'OFERTAS ESPECIAIS. FRETE GRÁTIS. LG',
  '649618-MLA71253252477_082023': 'OFERTAS ESPECIAIS. FRETE GRÁTIS. LG',
  '758917-MLA71253252479_082023': 'SEMANA GAMER DELL. ATÉ 40% OFF',
  '722240-MLA71284378252_082023': 'SEMANA GAMER DELL. ATÉ 40% OFF',
  '648039-MLA71284378276_082023': 'Seteção nintendo NA SEMANA GAMER',
  '609751-MLA71368217063_082023': 'Seteção nintendo NA SEMANA GAMER',
  '702465-MLA71368217521_082023': 'SEMANA GAMER. Até R$ 400 OFF. 18X SEM Juros. Intel',
  '620002-MLA71374268225_082023': 'SEMANA GAMER. Até R$ 400 OFF. 18X SEM Juros. Intel',
  '641176-MLA71374268267_082023': 'SAMSUNG É GAME',
  '965364-MLA71376215769_082023': 'SAMSUNG É GAME',
  '746405-MLA71376215797_082023': 'SAMSUNG É GAME',
  '802005-MLA71391964055_082023': 'SAMSUNG É GAME',
  '949171-MLA71348839946_082023': 'NÃO PERCA A CHANCE DE SUBIR DE NÍVEL. 18X SEM Juros, Ate 30% OFF. intel',
  '977188-MLA71349138322_082023': 'NÃO PERCA A CHANCE DE SUBIR DE NÍVEL. 18X SEM Juros, Ate 30% OFF. intel',
  '925338-MLA71392303599_082023': 'INTEL GAMER DAYS SUBA DE NIVEL ATÉ 18 X SEM JUROS COM MERCADO PAGO',
  '731712-MLA71349623268_082023': 'LOGITECH SEMANA GAMER ATUALIZE SEU SETUP ',
  '657173-MLA71349623294_082023': 'LOGITECH SEMANA GAMER ATUALIZE SEU SETUP ',
  '729235-MLA71500161257_092023': 'PAO ae Acúcar AS CERVEJAS FAVORITAS ATÉ 30% OFF',
  '751645-MLA71500161263_092023': 'PAO ae Acúcar AS CERVEJAS FAVORITAS ATÉ 30% OFF',
  '798277-MLA71525047383_092023': 'ADIDAS TODO ESTILO DAS 3 LISTRAS ATÉ 50% OFF',
  '796979-MLA71525047413_092023': 'ADIDAS TODO ESTILO DAS 3 LISTRAS ATÉ 50% OFF',
  '852818-MLA71525048835_092023': 'ADIDAS O MELHOR DE ESPORTE PARA você',
  '689725-MLA71525048857_092023': 'ADIDAS O MELHOR DE ESPORTE',
  '648567-MLA71580225726_092023': 'DESCONTÕES NO CARREFAS ATÉ 30% OFF',
  '938321-MLA71580176710_092023': 'DESCONTÕES NO CARREFAS ATÉ 30% OFF',
  '872557-MLA71788433705_092023': 'PAO ae Acúcar AS MELHORES CERVEJAS ATÉ 30% OFF',
  '901924-MLA71788433713_092023': 'PAO ae Acúcar AS MELHORES CERVEJAS ATÉ 30% OFF',
  '747791-MLA72003449306_102023': 'PAO ae Acúcar CERVEJAS FAVORITAS ATÉ 30% OFF',
  '761355-MLA72060648311_102023': 'PAO ae Acúcar CERVEJAS FAVORITAS ATÉ 30% OFF',
  '706420-MLA72052296658_102023': 'FISHER-PRICE , MEGA , HOTWHEELS , MATTEL SUAS MARCAS FAVORITAS , BARBIE , MATTEL GAMES , POLLY POCKET',
  '794678-MLA72052268368_102023': 'FISHER-PRICE , MEGA , HOTWHEELS , MATTEL SUAS MARCAS FAVORITAS , BARBIE , MATTEL GAMES , POLLY POCKET',
  '786021-MLA72386773841_102023': 'NOVA COLEÇÃO ADIDAS TODO O ESTILO DE ADIDAS + FARM ',
  '862855-MLA72386773929_102023': 'NOVA COLEÇÃO ADIDAS TODO O ESTILO DE ADIDAS + FARM ',
  '889795-MLA72418794388_102023': 'ESQUENTA BLACK ATÉ 45% OFF',
  '690684-MLA72419154858_102023': 'ESQUENTA BLACK ATÉ 45% OFF',
  '688840-MLA72461098094_102023': 'MERCADO PAGO GANHE UM CUPOM R$ 35 OFF CUPOM MP350OFF',
  '771707-MLA72461098220_102023': 'MERCADO PAGO GANHE UM CUPOM R$ 35 OFF CUPOM MP350OFF',
  '618172-MLA72574577689_102023': 'PAO ae Acúcar MELHORES OFERTAS ATÉ 30% OFF FRETE GRÁTIS',
  '927202-MLA72574577721_102023': 'PAO ae Acúcar MELHORES OFERTAS ATÉ 30% OFF',
  '814792-MLA72668668615_112023': 'AMBEV CERVEJAS E VINHOS ATÉ 40% OFF',
  '893088-MLA72596833006_112023': 'AMBEV CERVEJAS E VINHOS ATÉ 40% OFF',
  '855263-MLA72714594277_112023': 'SAMSUNG PREÇO EXCLUSIVO ATÉ 18 X SEM JUROS COM MERCADO PAGO FRETE GRATIS',
  '634777-MLA72714857955_112023': 'SAMSUNG PREÇO EXCLUSIVO ATÉ 18 X SEM JUROS COM MERCADO PAGO ',
  '891758-MLA72649794414_112023': 'SAMSUNG PREÇO EXCLUSIVO ATÉ 18 X SEM JUROS COM MERCADO PAGO ',
  '615062-MLA72649794636_112023': 'SAMSUNG PREÇO EXCLUSIVO ATÉ 18 X SEM JUROS COM MERCADO PAGO ',
  '854142-MLA72811754631_112023': 'DELL TECHNOLOGIES SUPER OFERTAS ATÉ 40% OFF',
  '661768-MLA72812275633_112023': 'DELL TECHNOLOGIES SUPER OFERTAS ATÉ 40% OFF',
  '718055-MLA72918444677_112023': 'PAO ae Acúcar CERVEJAS FAVORITAS ATÉ 30% OFF',
  '796223-MLA72842084932_112023': 'PAO ae Acúcar CERVEJAS FAVORITAS ATÉ 30% OFF',
  '606353-MLA72844410186_112023': 'BRASTEMP BLACK BRASTEMP ATÉ R$ 900 OFF FRETE GRATIS',
  '980696-MLA72844311814_112023': 'BRASTEMP BLACK BRASTEMP ATÉ R$ 900 OFF',
  '947118-MLA72923134353_112023': 'SANTANDER SUPERBLACK SANTANDER +R$ 20 OFF CUPOM SANTANDER20',
  '736753-MLA72923486967_112023': 'SANTANDER SUPERBLACK SANTANDER +R$ 20 OFF CUPOM SANTANDER20',
  '997350-MLA72923467821_112023': 'SANTANDER SUPERBLACK SANTANDER +R$40 OFF CUPOM : SANTANDER40',
  '963173-MLA72846615008_112023': 'SANTANDER SUPERBLACK SANTANDER +R$40 OFF CUPOM : SANTANDER40',
  '948710-MLA72923468205_112023': 'SANTANDER SUPERBLACK SANTANDER +R$100 OFF',
  '958733-MLA72923488067_112023': 'SANTANDER SUPERBLACK SANTANDER +R$100 OFF',
  '908966-MLA72860296950_112023': 'JBL A BLACK MAIS LARANJA DE TODAS',
  '972911-MLA72860267992_112023': 'JBL A BLACK MAIS LARANJA ',
  '927655-MLA72939984065_112023': 'SAMSING ECOSSITEMA É SAMSUNG ',
  '915116-MLA72939993981_112023': 'SAMSING ECOSSITEMA É SAMSUNG ',
  '752908-MLA72941083027_112023': 'HERING RENOVE SEU LOOK ATÉ 70% OFF + 20% OFF',
  '829701-MLA72941131849_112023': 'HERING RENOVE SEU LOOK ATÉ 70% OFF ',
  '890742-MLA72942002271_112023': 'PREÇO EXCLUSIVO SAMSUNG',
  '929593-MLA72942246119_112023': 'PREÇO EXCLUSIVO SAMSUNG',
  '668922-MLA72864967660_112023': 'INTEL OFERTAS INTEL ATÉ 40% OFF',
  '925061-MLA72864987512_112023': 'INTEL OFERTAS INTEL ATÉ 40% OFF',
  '907770-MLA72942251647_112023': 'ACER GARANTA O SEU SETUP ATÉ 20% OFF',
  '795730-MLA72865037892_112023': 'ACER GARANTA O SEU SETUP ATÉ 20% OFF',
  '790987-MLA72942945241_112023': 'AMBEV CERVEJAS E VINHOS ATÉ 40% OFF',
  '935799-MLA72865318942_112023': 'AMBEV CERVEJAS E VINHOS ATÉ 40% OFF',
  '924234-MLA72865766346_112023': 'JBL A BLACK MAIS LARANJA DE TODAS',
  '862055-MLA72865824826_112023': 'JBL A BLACK MAIS LARANJA',
  '693901-MLA72996069629_112023': 'HERING BLACK FRIDAY ATÉ 75% OFF',
  '939560-MLA72917261942_112023': 'HERING BLACK FRIDAY ATÉ 75% OFF',
  '611376-MLA72995813623_112023': 'HERING CYBER MONDAY ATÉ 50% OFF',
  '931529-MLA72995774105_112023': 'HERING CYBER MONDAY ATÉ 50% OFF',
  '909777-MLA72998989717_112023': 'UNILEVER PARA CUIDAR DA CASA ATÉ 50% OFF',
  '616313-MLA72999107803_112023': 'UNILEVER PARA CUIDAR DA CASA ATÉ 50% OFF',
  '793089-MLA72998961007_112023': 'LOREAL PRODUCTOS DE BELEZA ATÉ 40% OFF',
  '940477-MLA72998961063_112023': 'LOREAL PRODUCTOS DE BELEZA ATÉ 40% OFF',
  '751339-MLA73020148253_112023': 'ELECTROLUX OFERTAS ELECTROLUX BLACK NOVEMBRO',
  '611940-MLA72940709022_112023': 'ELECTROLUX OFERTAS ELECTROLUX BLACK NOVEMBRO',
  '677086-MLA73022048029_112023': 'CONSUL BLACK CONSUL ATÉ R$900 OFF',
  '671288-MLA73021998907_112023': 'CONSUL BLACK CONSUL ATÉ R$900 OFF',
  '744776-MLA73040513613_112023': 'BLACK CARREFOUR ATÉ 30% OFF',
  '955626-MLA73040582823_112023': 'BLACK CARREFOUR ATÉ 30% OFF',
  '864009-MLA72963441750_112023': 'Black Friday Philips Colgate',
  '767948-MLA72963441816_112023': 'Black Friday Philips Colgate',
  '875469-MLA72968696888_112023': 'Iphone 12 rápido até na entrega',
  '717284-MLA72968677592_112023': 'Iphone 12 rápido até na entrega',
  '785086-MLA73059001915_112023': 'Black Friday WAP Loja Oficial',
  '917895-MLA73059574717_112023': 'Black Friday WAP Loja Oficial',
};
