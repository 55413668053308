const PATH_ADN = '/advertising/adn';
const SOURCE = 'template';
const ACTION_TYPE = 'cleanAndSend';
const STATUS_ERROR = 'error';

const trackEvent = (trackEventData: TrackEventData) => {
  if (window.melidata) {
    window.melidata(ACTION_TYPE, 'event', {
      path: PATH_ADN,
      event_data: trackEventData,
    });
  }
};

export const trackPrintError = (reason: string, url?: string) => {
  const event_data = { status: STATUS_ERROR, source: SOURCE, reason };
  trackEvent(url ? { ...event_data, url } : event_data);
};
